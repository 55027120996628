import { A } from "@solidjs/router";

import GetCodeModal from "../GetCodeModal";
import ShareModal from "./ShareModal";
import JulepLogo from "../misc/JulepBlack";
import { usePlayground } from "~/context/PlaygroundProvider";
import { Match, Switch } from "solid-js";
import SessionDrawer from "./SessionDrawer";

type Props = {};

export default function PlaygroundHeader({}: Props) {
  const [{ currentMode }] = usePlayground();
  return (
    <div class="z-50 flex h-[60px] flex-row items-center justify-between border-b border-gray-300 bg-white px-4">
      <div class="text-xl font-bold flex flex-row gap-2">
        <div>
          <img src="/images/logo.png" />
        </div>
        <div>
          <Switch>
            <Match when={currentMode() === "model"}>Model Playground</Match>
            <Match when={currentMode() === "agent"}>Workspace</Match>
          </Switch>
        </div>
        <SessionDrawer />
      </div>
      <div class="flex flex-row gap-x-4">
        <GetCodeModal />
        <ShareModal />
      </div>
    </div>
  );
}
