import { Show, createEffect, createSignal, onMount } from "solid-js";
import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";
import * as Dialog from "~/components/ui/dialog";
import { Copy, Share2, X } from "lucide-solid";
import Share from "../icons/Share";
import { useAction, useSubmission } from "@solidjs/router";
import { getShortUrlAction } from "~/api";
import { useMixpanel } from "~/context/MixpanelProvider";

type Props = {
  class?: string;
};

export default function ShareModal(props: Props) {
  const [{ jwt }] = useAuth();
  const [url, setUrl] = createSignal("");
  const [isOpen, setIsOpen] = createSignal(false);
  const [_, { compressJson, toast }] = useJulep();
  const { mixpanel } = useMixpanel();

  const getShorUrl = useAction(getShortUrlAction);
  const getShorUrlStatus = useSubmission(getShortUrlAction);

  createEffect(() => {
    if (!isOpen()) return;
    const data = compressJson();

    getShorUrl(data)
      .then((res) => {
        const url = `${window.location.origin}/short/${res}`;
        setUrl(url);
      })
      .catch((err) => console.error(err));
  });

  const handleCopyCode = () => {
    navigator.clipboard.writeText(url());
    toast().success({
      title: "Copied to clipboard!",
    });
    mixpanel.track("Share Link Copied");
  };

  return (
    <Dialog.Root onOpenChange={(val) => setIsOpen(val.open)}>
      <Dialog.Trigger
        class={`flex h-12 flex-row items-center justify-center rounded-full bg-gray-100 p-3 text-sm font-semibold hover:bg-secondary disabled:cursor-not-allowed disabled:opacity-40 ${props.class}`}
        disabled={!jwt()}
      >
        <Share2 class="w-4 h-4 mr-2" />
        Share
      </Dialog.Trigger>
      <Dialog.Backdrop class="bg-black bg-opacity-50" />
      <Dialog.Positioner>
        <Dialog.Content>
          <div class="relative m-4 max-w-2xl rounded-2xl bg-white p-5">
            <div class="absolute right-0 top-0 m-2">
              <Dialog.CloseTrigger class="rounded p-2 text-primary hover:bg-primary/50">
                <X />
              </Dialog.CloseTrigger>
            </div>
            <h1 class="text-2xl font-bold text-primary">Share preset</h1>
            <p class="mt-4 text-lg text-primary/80">
              Anyone who has this link can view the prompt and the conversation.
            </p>
            <div class="mt-6 flex space-x-2">
              <div class="relative w-full items-center gap-3 overflow-hidden text-ellipsis whitespace-nowrap rounded border border-black p-2 text-primary/80 transition-colors">
                <Show when={getShorUrlStatus.pending} fallback={url()}>
                  Creating Url
                </Show>
              </div>
              <div class="flex ">
                <button
                  class="flex h-12 w-32 flex-grow items-center justify-center rounded-full bg-[#FFC776] py-4 font-bold hover:bg-[#FFD291] disabled:cursor-not-allowed disabled:opacity-40"
                  onClick={handleCopyCode}
                  disabled={getShorUrlStatus.pending}
                >
                  <Copy class="mr-2 w-4 h-4" />
                  Copy link
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
}
