import { createEffect, createSignal } from "solid-js";
import { createCodeMirror, createEditorReadonly, createEditorControlledValue } from "solid-codemirror";
import { abcdef } from "@uiw/codemirror-themes-all";
import { lineNumbers } from "@codemirror/view";
import { langs } from "@uiw/codemirror-extensions-langs";

import { useAuth } from "~/context/AuthProvider";
import { useJulep } from "~/context/JulepProvider";
import * as Dialog from "~/components/ui/dialog";
import { X, Copy, Code2 } from "lucide-solid";
import * as Toast from "./ui/toast";
import { useMixpanel } from "~/context/MixpanelProvider";

type Props = {
  class?: string;
};

const start = "<|im_start|>";
const end = "<|im_end|>";

const chatCompletionUrl = "https://api-alpha.julep.ai/v1/chat/completions";
const chatUrl = "https://api-alpha.julep.ai/v1/chat/completions";

export default function GetCodeModal(props: Props) {
  const [{ jwt }] = useAuth();
  const [{ messages, config, systemMessage }, { toast }] = useJulep();
  const [code, setCode] = createSignal("");
  const [chatml, setChatml] = createSignal("");
  const [python, setPython] = createSignal("");
  const [shell, setShell] = createSignal("");
  const [javascript, setJavascript] = createSignal("");
  const [language, setLanguage] = createSignal("python");
  const [readOnly, setReadOnly] = createSignal(true);
  const { mixpanel } = useMixpanel();

  const { editorView, ref: editorRef, createExtension } = createCodeMirror();

  createEditorReadonly(editorView, readOnly);
  createEditorControlledValue(editorView, code);

  // Add a static custom theme
  createExtension(abcdef);
  createExtension(langs.javascript());
  createExtension(langs.python());

  // Toggle extension
  createExtension(() => lineNumbers());

  createEffect(() => {
    const config_ = config();
    if (!messages()) return;
    const formattedMessagesJson = messages().reduce(
      // @ts-ignore
      (prev, cur) => {
        return [
          ...prev,
          {
            role: cur.role,
            name: cur.name,
            content: cur.content,
          },
        ];
      },
      [
        {
          role: systemMessage().role,
          name: systemMessage().name,
          content: systemMessage().content,
        },
      ]
    );

    const formattedMessagesChatml = messages().reduce((prev, cur, i, arr) => {
      return `${prev}\n${start}${cur.role === "system" ? cur.name : cur.role} ${
        cur.role !== "system" && cur.name ? `(${cur.name})` : ""
      }\n${cur.content}${end}`;
    }, `${start}situation\n${systemMessage().content}${end}`);

    let pythonCode = `from julep import Client

api_key = "<api-key>"

client = Client(api_key=api_key)

messages = ${JSON.stringify(formattedMessagesJson, null, 4)}

chat_completion = client.chat.completions.create(
    model="${config_.model}",
    messages=messages,
    temperature=${config_.temperature},
    max_tokens=${config_.max_tokens},
    top_p=${config_.top_p}
)

print(chat_completion.choices[0].message.content)
`;

    let pythonChatmlCode = `from julep import Client

api_key = "<api-key>"

client = Client(api_key=api_key)

prompt = """
${formattedMessagesChatml}
"""

completion = client.completions.create(
    model="${config_.model}",
    prompt=prompt,
    temperature=${config_.temperature},
    max_tokens=${config_.max_tokens},
    top_p=${config_.top_p}
)

print(completion.choices[0].text)
`;

    let shellCode = `curl --location '${chatCompletionUrl}' \\
--header 'Authorization: Bearer <api-key>' \\
--header 'Content-Type: application/json' \\
--data '{
    "model": "${config_.model}",
    "messages": ${JSON.stringify(formattedMessagesJson, null, 4)},
    "temperature": ${config_.temperature},
    "max_tokens": ${config_.max_tokens},
    "top_p": ${config_.top_p},
    "frequency_penalty": ${config_.frequency_penalty},
    "presence_penalty": ${config_.presence_penalty}
}'
`;

    let javascriptCode = `const julep = require("@julep/sdk")

const apiKey = "<api-key>";

const client = new julep.Client({ apiKey });

const messages = ${JSON.stringify(formattedMessagesJson, null, 4)}

client.chat.completions
  .create({
    model: "${config_.model}",
    messages: messages,
    temperature: ${config_.temperature},
    max_tokens: ${config_.max_tokens},
    top_p: ${config_.top_p},
  })
  .then((res) => console.log(res.choices[0].message.content));
    `;

    setPython(pythonCode);
    setChatml(pythonChatmlCode);
    setShell(shellCode);
    setCode(pythonCode);
    setJavascript(javascriptCode);
  });

  createEffect(() => {
    if (language() === "python") {
      setCode(python());
    } else if (language() === "chatml") {
      setCode(chatml());
    } else if (language() === "javascript") {
      setCode(javascript());
    } else {
      setCode(shell());
    }
  });

  const handleCopyCode = () => {
    toast().success({
      title: "Copied to clipboard!",
    });
    navigator.clipboard.writeText(code());
    mixpanel.track(`Copied ${language()} code`);
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger
        class={` flex h-12 flex-row items-center justify-center rounded-full bg-gray-100 p-3 text-sm font-semibold hover:bg-secondary disabled:cursor-not-allowed disabled:opacity-40 ${props.class}`}
        disabled={!jwt()}
      >
        <Code2 class="mr-2 w-4 h-4" />
        Get code
      </Dialog.Trigger>
      <Dialog.Backdrop class="bg-black bg-opacity-50" />
      <Dialog.Positioner>
        <Dialog.Content class="relative m-4 max-w-2xl rounded-2xl bg-white p-5 shadow-xl">
          <div class="absolute right-0 top-0 m-2">
            <Dialog.CloseTrigger class="rounded p-2 text-primary hover:bg-primary/50">
              <X />
            </Dialog.CloseTrigger>
          </div>
          <h1 class="text-2xl font-bold text-primary">Get code</h1>
          <p class="mt-4 text-sm text-primary/80">
            You can use the following code to start integrating your current prompt and settings into your application.
          </p>

          <div class="mt-4 flex justify-between">
            <select
              name="language"
              id="language"
              value={language()}
              class="text-md h-12 resize-none rounded border border-gray-300 bg-white p-1 font-semibold uppercase text-gray-700 focus:border-transparent focus:bg-white focus:outline-none focus:ring-1 focus:ring-blue-600"
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option value="python" class="normal-case">
                Python (json)
              </option>
              <option value="chatml" class="normal-case">
                Python (chatml)
              </option>
              <option value="javascript" class="normal-case">
                Node.js
              </option>
              <option value="shell" class="normal-case">
                Shell
              </option>
            </select>
            <button
              class="flex h-12 flex-row items-center justify-center rounded-full bg-primary p-3 font-semibold hover:bg-secondary"
              onClick={handleCopyCode}
            >
              <Copy class="mr-2" />
              Copy code
            </button>
          </div>
          <div class="mt-8 flex justify-center space-x-4">
            <div
              ref={editorRef}
              style={{
                height: "500px",
                width: "586px",
                overflow: "scroll",
              }}
            />
          </div>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
}
