import { Link, Meta, Title } from "@solidjs/meta";
import type { RouteSectionProps } from "@solidjs/router";
import { Spinner, SpinnerType } from "solid-spinner";

import Sidebar from "~/components/Sidebar/Sidebar";
import PlaygroundHeader from "~/components/playground/PlaygroundHeader";
import { createClient } from "~/utils/supabase/client";
import { Show, createSignal, onMount } from "solid-js";
import { useMixpanel } from "~/context/MixpanelProvider";
import { useJulep } from "~/context/JulepProvider";

export default function PlaygroundLayout(props: RouteSectionProps) {
  const { mixpanel } = useMixpanel();
  const supabase = createClient();

  onMount(async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    mixpanel?.identify(user?.id);
  });
  return (
    <>
      <>
        <Title>Playground - JulepAI</Title>
        <Meta name="description" content="JulepAI playground." />
        <Meta name="viewport" content="width=device-width, initial-scale=1" />
      </>
      <main class="relative flex max-h-screen h-screen w-screen flex-col items-center justify-center">
        {/* <Show when={initialized()} fallback={<Spinner type={SpinnerType.spinningCircles} />}> */}
        <div class="flex flex-row w-full h-full">
          {/* <Sidebar /> */}
          <div class="w-full ">
            <PlaygroundHeader />
            {props.children}
          </div>
        </div>
        {/* </Show> */}
      </main>
    </>
  );
}
