import * as Drawer from "~/components/ui/drawer";
import { Button } from "~/components/ui/button";
import { useJulep } from "~/context/JulepProvider";
import { For, Show, createEffect, createMemo } from "solid-js";
import { Session } from "@julep/sdk/dist/cjs/api";

const formatDate = (dateString: string): string => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const date = new Date(dateString);
  const dayOfWeek = days[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${dayOfWeek}, ${day}-${month}-${year}`;
};

function groupSessionsByDate(sessions: Session[]): Record<string, Session[]> {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const last7Days = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
  const last30Days = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);

  const groupedSessions: Record<string, Session[]> = {
    Today: [],
    "Last 7 Days": [],
    "Last 30 Days": [],
  };

  sessions.forEach((session) => {
    const sessionDate = new Date(session.updated_at!);
    sessionDate.setHours(0, 0, 0, 0);

    if (sessionDate >= today) {
      groupedSessions["Today"].push(session);
    } else if (sessionDate >= last7Days) {
      groupedSessions["Last 7 Days"].push(session);
    } else if (sessionDate >= last30Days) {
      groupedSessions["Last 30 Days"].push(session);
    } else {
      const monthYearKey = `${sessionDate.toLocaleString("default", { month: "long" })} ${sessionDate.getFullYear()}`;
      if (!groupedSessions[monthYearKey]) {
        groupedSessions[monthYearKey] = [];
      }
      groupedSessions[monthYearKey].push(session);
    }
  });

  return groupedSessions;
}

export default function SessionDrawer() {
  const [{ sessions }, { selectSession, createSession }] = useJulep();

  const groupedSessionsArray = createMemo(() => {
    if (!sessions()) return [];
    return Object.entries(groupSessionsByDate(sessions()!));
  });

  const show = createMemo(() => sessions() && sessions()!?.length > 0 && groupedSessionsArray().length > 0, false);

  return (
    <Show when={show()}>
      <Drawer.Root variant="left">
        {(api) => (
          <>
            <Drawer.Trigger as={Button} variant="solid" size="xs">
              Show History
            </Drawer.Trigger>
            <Drawer.Backdrop />
            <Drawer.Positioner>
              <Drawer.Content>
                <Drawer.Header>
                  <Drawer.Title>Session History</Drawer.Title>
                  <Drawer.Description>
                    <Button
                      variant="subtle"
                      size="sm"
                      onClick={() => {
                        createSession();
                        api().close();
                      }}
                    >
                      Create Session
                    </Button>
                  </Drawer.Description>
                </Drawer.Header>
                <Drawer.Body>
                  <For each={groupedSessionsArray()}>
                    {(group) => (
                      <div>
                        <h2 class="text-sm font-medium  text-gray-400">{group[0]}</h2>
                        <div class="flex flex-col gap-2">
                          <For each={group[1]}>
                            {(session) => (
                              <Button
                                variant="ghost"
                                class="font-normal flex justify-start"
                                onClick={() => {
                                  selectSession(session.id);
                                  api().close();
                                }}
                              >
                                <div class="truncate">
                                  {formatDate(session.updated_at!)} - {session.situation}
                                </div>
                              </Button>
                            )}
                          </For>
                        </div>
                      </div>
                    )}
                  </For>
                </Drawer.Body>
              </Drawer.Content>
            </Drawer.Positioner>
          </>
        )}
      </Drawer.Root>
    </Show>
  );
}
